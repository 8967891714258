<template>
  <div>
    <v-data-table
      dense
      :headers="headers"
      :items="lista"
      :loading="cargandoTabla"
      class="elevation-1"
      :items-per-page="registrosPorPaginaValue"
      hide-default-footer
    >
      <template v-slot:top>
        <v-card>
          <v-card-title class="align-start">
            <span class="font-weight-semibold">{{ tituloTabla }}</span>
            <v-spacer></v-spacer>
            <!-- Menu para el buscar -->
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :loading="cargando"
                  small
                  color="accent"
                  v-bind="attrs"
                  v-on="on"
                  @click="dialogSearch=true"
                >
                  <v-icon color="primary">
                    {{ icons.mdiFilter }}
                  </v-icon>
                </v-btn>
              </template>
              <span>Filtrar</span>
            </v-tooltip>
            <v-dialog
              v-model="dialogSearch"
              scrollable
              max-width="700px"
              persistent
            >
              <v-card>
                <v-card-title>
                  Buscar eventos
                  <v-spacer></v-spacer>
                  <v-icon>{{ icons.mdiFilter }}</v-icon>
                </v-card-title>
                <v-card-text
                  class="py-5"
                  style="height: 300px;"
                >
                  <v-row>
                    <v-col cols="12">
                      <v-autocomplete
                        v-model="objSearch.listfiles"
                        :items="filterFiles"
                        item-value="id"
                        item-text="name"
                        outlined
                        dense
                        chips
                        small-chips
                        label="Filtrar por"
                        multiple
                        hide-details
                      >
                        <template v-slot:selection="data">
                          <v-chip
                            class="elevation-2 my-1"
                            v-bind="data.attrs"
                            :input-value="data.selected"
                            close
                            @click="data.select"
                            @click:close="remove(data.item)"
                          >
                            {{ data.item.name }}
                          </v-chip>
                        </template>
                      </v-autocomplete>
                    </v-col>

                    <v-col
                      v-if="isFirstNameSelected"
                      cols="12"
                    >
                      <v-text-field
                        v-model="objSearch.FirstName"
                        label="Nombre"
                        outlined
                        dense
                        placeholder="Buscar por nombre"
                        hide-details
                      ></v-text-field>
                    </v-col>

                    <v-col
                      v-if="isLastNameSelected"
                      cols="12"
                    >
                      <v-text-field
                        v-model="objSearch.LastName"
                        label="Apellidos"
                        outlined
                        dense
                        placeholder="Buscar por apellidos"
                        hide-details
                      ></v-text-field>
                    </v-col>

                    <v-col
                      v-if="isEmailSelected"
                      cols="12"
                    >
                      <v-text-field
                        v-model="objSearch.Email"
                        label="Correo"
                        outlined
                        dense
                        type="email"
                        placeholder="Buscar por correo"
                        hide-details
                      ></v-text-field>
                    </v-col>

                    <!-- Estado -->
                    <v-col
                      v-if="isStatusSelected"
                      cols="12"
                    >
                      <v-select
                        v-model="objSearch.Status"
                        :items="estados"
                        item-value="id"
                        item-text="name"
                        label="Estado"
                        dense
                        outlined
                        return-object
                      >
                      </v-select>
                    </v-col>
                    <v-col
                      v-if="isIdentificationSelected"
                      cols="12"
                    >
                      <v-text-field
                        v-model="objSearch.Identification"
                        label="Identificación"
                        outlined
                        dense
                        placeholder="Buscar por identificación"
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col
                      v-if="isPhoneSelected"
                      cols="12"
                    >
                      <v-text-field
                        v-model="objSearch.Phone"
                        label="Telefono"
                        outlined
                        dense
                        placeholder="Buscar por telefono"
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col
                      v-if="isLocationSelected"
                      cols="12"
                    >
                      <v-text-field
                        v-model="objSearch.Location"
                        label="Locación"
                        outlined
                        dense
                        placeholder="Buscar por locación"
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-btn
                    type="reset"
                    outlined
                    class="mx-2"
                    @click="closeDialogsearch"
                  >
                    Cancelar
                  </v-btn>
                  <v-btn
                    color="primary"
                    @click="search"
                  >
                    Buscar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-divider
              class="mx-3"
              vertical
            ></v-divider>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :loading="cargandoTabla"
                  small
                  color="accent"
                  @click="closeDialogsearch"
                  v-on="on"
                >
                  <v-icon color="primary">
                    {{ icons.mdiReload }}
                  </v-icon>
                </v-btn>
              </template>
              <span>Recargar</span>
            </v-tooltip>
            <v-divider
              class="mx-3"
              vertical
            ></v-divider>
            <v-btn
              v-if="haveCreate"
              :loading="cargando"
              small
              color="primary"
              @click="abrirForm"
            >
              Nuevo
            </v-btn>
          </v-card-title>
        </v-card>
      </template>
      <template v-slot:[`item.imageUrl`]="{ item }">
        <v-img
          contain
          max-height="40"
          max-width="40"
          :src="item.imageUrl"
          class="me-3"
        ></v-img>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-select
          v-if="isActiveFieldState"
          v-model="userState"
          class="my-1"
          :items="estados"
          item-value="id"
          item-text="name"
          dense
          outlined
          hide-details
        ></v-select>
        <span v-if="!isActiveFieldState">
          <v-chip
            v-if="item.status === 'active'"
            small
            class="ma-2 elevation-1"
            color="success"
            text-color="white"
          >
            Activo
          </v-chip>
          <v-chip
            v-if="item.status === 'inactive'"
            small
            class="ma-2 elevation-1"
            color="warning"
            text-color="white"
          >
            Inactivo
          </v-chip>
          <v-chip
            v-if="item.status === 'deleted'"
            small
            class="ma-2 elevation-1"
            color="error"
            text-color="white"
          >
            Eliminado
          </v-chip>
        </span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="haveEdit"
              :loading="cargandoEditar"
              class="ma-1"
              x-small
              color="primary"
              @click="editItem(item)"
              v-on="on"
            >
              <v-icon small>
                {{ icons.mdiPencil }}
              </v-icon>
            </v-btn>
          </template>
          <span>Editar</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <!-- Paginado -->
    <div
      v-if="lista.length > 0"
      class="d-flex align-center justify-space-between"
    >
      <v-pagination
        v-model="page"
        :circle="true"
        :length="longitudMaximaNum"
        :next-icon="icons.mdiArrowRight"
        :prev-icon="icons.mdiArrowLeft"
        :page="cantidadPaginas"
        :total-visible="totalVisible"
        @input="next"
      ></v-pagination>
      <span
        class="compact-form mt-0 mb-0 pt-0 pb-0"
        style="width: 140px"
      >
        <v-select
          v-model="registrosPorPaginaValue"
          :items="registrosPorPagina"
          placeholder="Cant. Registros"
          hide-details
          @change="onChangePagesCombo"
        ></v-select>
      </span>
    </div>
    <!-- Formulario de Confirmación -->
    <confirm ref="confirm"></confirm>
    <v-snackbar
      v-model="notificaciones.snackbar"
      :bottom="notificaciones.y === 'bottom'"
      :color="notificaciones.color"
      :left="notificaciones.x === 'left'"
      :multi-line="notificaciones.mode === 'multi-line'"
      :right="notificaciones.x === 'right'"
      :timeout="notificaciones.timeout"
      :top="notificaciones.y === 'top'"
      :vertical="notificaciones.mode === 'vertical'"
    >
      {{ notificaciones.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          dark
          text
          v-bind="attrs"
          @click="notificaciones.snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import {
  mdiPencil, mdiDelete, mdiArrowLeft,
  mdiArrowRight, mdiContentSaveOutline,
  mdiFilter,
  mdiReload,
} from '@mdi/js'
import { reactive, ref, computed } from '@vue/composition-api'
import HTTP from '@/utils/axios/axios-config-base'
import { useRouter } from '@/utils'
import Confirm from '@/components/Confirm.vue'

export default {
  components: {
    Confirm,
  },
  setup() {
    const { router, route } = useRouter()
    const tituloTabla = ref('Funcionarios Municipales')
    const confirm = ref(null)
    const cargandoTabla = ref(false)
    const cargando = ref(false)
    const cargandoEditar = ref(false)
    const cargandoDelete = ref(false)
    const isActiveFieldState = ref(false)
    const objUser = reactive({})
    const userState = ref(null)
    const notificaciones = reactive({
      snackbar: false,
      color: '',
      mode: '',
      text: '',
      timeout: 3000,
      x: null,
      y: 'top',
    })

    // Search
    const filterFiles = [
      // { id: 'nombre_desc', name: 'Nombre / Descripción' },
      { id: 'first_name', name: 'Nombre' },
      { id: 'last_name', name: 'Apellidos' },
      { id: 'email', name: 'Correo' },
      { id: 'status', name: 'Estado' },

      // { id: 'identification', name: 'Identificación' },
      // { id: 'phone', name: 'Telefono' },
      // { id: 'location', name: 'Locación' },
    ]

    const getInitialData = () => ({
      listfiles: [],
      Query: '',
      Email: '',
      FirstName: '',
      LastName: '',
      Status: '',
      Identification: '',
      Phone: '',
      Location: '',
    })
    const objSearch = reactive({
      listfiles: [],
      Query: '',
      Email: '',
      FirstName: '',
      LastName: '',
      Status: '',
      Identification: '',
      Phone: '',
      Location: '',
    })
    const dialogSearch = ref(false)
    const estados = [
      { id: 'active', name: 'Activo' },
      { id: 'inactive', name: 'Inactivo' },
      { id: 'deleted', name: 'Eliminado' },
    ]
    const headers = ref([
      {
        text: 'Nombre', align: 'start', value: 'firstName',
      },
      { text: 'Apellidos', value: 'lastName' },
      { text: 'Correo', value: 'email' },
      { text: 'Estado', value: 'status', width: '200' },
      {
        text: 'Acción',
        value: 'actions',
        align: 'center',
        width: '120',
        sortable: false,
      },
    ])
    const lista = ref([])
    const permissions = localStorage.getItem('permissions')
    const haveDelete = computed(() => permissions.includes('Users:Manager:FullAccess') || permissions.includes('Users:Manager:Delete'))
    const haveEdit = computed(() => permissions.includes('Users:Manager:FullAccess') || permissions.includes('Users:Manager:ReadEditAccess'))
    const haveCreate = computed(() => permissions.includes('Users:Manager:FullAccess') || permissions.includes('Users:Manager:CreateAccess'))

    const isQuerySelected = computed(() => objSearch.listfiles.includes('nombre_desc'))
    const isFirstNameSelected = computed(() => objSearch.listfiles.includes('first_name'))
    const isLastNameSelected = computed(() => objSearch.listfiles.includes('last_name'))
    const isEmailSelected = computed(() => objSearch.listfiles.includes('email'))
    const isStatusSelected = computed(() => objSearch.listfiles.includes('status'))
    const isIdentificationSelected = computed(() => objSearch.listfiles.includes('identification'))
    const isPhoneSelected = computed(() => objSearch.listfiles.includes('phone'))
    const isLocationSelected = computed(() => objSearch.listfiles.includes('location'))

    // paginacion
    const page = ref(1)
    const longitudMaximaNum = ref(0)
    const cantidadPaginas = ref(5)
    const totalVisible = ref(5)
    const registrosPorPaginaValue = ref(50)
    const registrosPorPagina = ref([
      5, 10, 20, 50,
    ])

    const listar = async (pagina = 1) => {
      if (pagina <= 0) {
        pagina = 1
      }
      try {
        lista.value = []
        cargandoTabla.value = true
        let search = ''
        if (objSearch.Query) {
          search += `&Query=${objSearch.Query}`
        }
        if (objSearch.FirstName) {
          search += `&FirstName=${objSearch.FirstName}`
        }
        if (objSearch.LastName) {
          search += `&LastName=${objSearch.LastName}`
        }
        if (objSearch.Email) {
          search += `&Email=${objSearch.Email}`
        }
        if (objSearch.Status) {
          search += `&Status=${objSearch.Status ? objSearch.Status.id : ''}`
        }
        if (objSearch.Identification) {
          search += `&Identification=${objSearch.Identification}`
        }
        if (objSearch.Phone) {
          search += `&Phone=${objSearch.Phone}`
        }
        if (objSearch.Location) {
          search += `&Location=${objSearch.Location}`
        }
        const URL = `${HTTP.defaults.baseURL}identity-api/manager?page=${page.value}&pageSize=${registrosPorPaginaValue.value}${search}`
        const resp = await HTTP.get(URL)
        if (resp.data.entities.length > 0) {
          for (let index = 0; index < resp.data.entities.length; index += 1) {
            const obj = {
              ...resp.data.entities[index],
              isLoading: false,
            }
            lista.value.push(obj)
          }
          cargandoTabla.value = false

          // para evitar error de slint missin radix parameter
          // var num = parseInt("071", 10);  // 71
          longitudMaximaNum.value = parseInt((resp.data.pagination.total / registrosPorPaginaValue.value), 10)
        + (resp.data.pagination.total % registrosPorPaginaValue.value === 0 ? 0 : 1)
        } else {
          cargandoTabla.value = false
        }
      } catch (error) {
        cargandoTabla.value = false
      }
    }
    const cargarNotificaciones = async () => {
      if (route.value.params.notificaciones !== undefined) {
        notificaciones.color = route.value.params.notificaciones.color
        notificaciones.text = route.value.params.notificaciones.text
        notificaciones.snackbar = route.value.params.notificaciones.snackbar
      }
    }
    const search = async () => {
      await listar(page.value)

      Object.assign(objSearch, getInitialData())
      dialogSearch.value = false
    }
    const closeDialogsearch = async () => {
      Object.assign(objSearch, getInitialData())
      listar(page.value)
      dialogSearch.value = false
    }
    cargarNotificaciones()
    listar(page.value)

    return {
      lista,
      headers,
      router,
      tituloTabla,
      confirm,
      userState,
      estados,
      isActiveFieldState,
      objUser,
      notificaciones,
      filterFiles,
      isQuerySelected,
      isFirstNameSelected,
      isLastNameSelected,
      isStatusSelected,
      isEmailSelected,
      isIdentificationSelected,
      isPhoneSelected,
      isLocationSelected,
      dialogSearch,
      objSearch,
      getInitialData,
      search,
      closeDialogsearch,

      haveCreate,
      haveEdit,
      haveDelete,

      // loadin
      cargando,
      cargandoTabla,
      cargandoEditar,
      cargandoDelete,

      // icons
      icons: {
        mdiPencil,
        mdiDelete,
        mdiArrowLeft,
        mdiArrowRight,
        mdiContentSaveOutline,
        mdiFilter,
        mdiReload,
      },

      // paginacion
      page,
      longitudMaximaNum,
      cantidadPaginas,
      totalVisible,
      registrosPorPaginaValue,
      registrosPorPagina,

      // Metodos

      listar,
      abrirForm: () => {
        cargando.value = true
        setTimeout(() => {
          router.push({ name: 'crear_usuarios_miembros' })
          cargando.value = false
        }, 500)
      },

      onChangePagesCombo: () => {
        listar(page.value)
      },
      next: () => {
        listar(page.value)
      },

      editItem: item => {
        const index = lista.value.indexOf(item)
        router.push({ name: 'editar_usuarios_miembros', params: { id: lista.value[index].id } })
      },
      saveItem: async item => {
        item.isLoading = true
        isActiveFieldState.value = false
        item.status = userState.value

        try {
          const URL = `${HTTP.defaults.baseURL}identity-api/manager/${item.id}`
          const resp = await HTTP.put(URL, item)
          if (resp.status === 200) {
            item.isLoading = false
            listar(page.value)
          }
        } catch (error) {

        }
      },

      deleteItem: async item => {
        const index = lista.value.indexOf(item)
        confirm.value
          .open(
            'Cancelar Evento',
            '¿Está seguro que desea eliminar el Evento seleccionado?',
            {
              color: 'primary',
              width: '450',
            },
          )
          .then(confirm => {
            if (confirm) {
              const URL = `${HTTP.defaults.baseURL}events-api/${
                lista.value[index].eventId}/events`

              // approved
              // Canceled
              // pending
              const resp = HTTP.patch(URL, { status: 'approved' })
              listar()
            } else {

            }
          })
      },
      agree: async () => {
        if (dialog) {
          const URL = `${HTTP.defaults.baseURL}events-api/events`
          obj.value.name = `${obj.value.name}-copia`

          const resp = await HTTP.post(URL, obj.value)
          if (resp.status === 201) {
            listar()
            dialog.value = false
          }
        }
      },

      cancel: async () => {
        obj.value = objDefault.value
        dialog.value = false
      },

      remove: async item => {
        const index = objSearch.listfiles.indexOf(item.id)
        if (index >= 0) objSearch.listfiles.splice(index, 1)
      },
    }
  },
}
</script>
